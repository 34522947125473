<template>
  <!--Cancel Button-->
  <v-btn text @click.stop="$emit('passModalClose', false)">
    <v-icon left small>mdi-close</v-icon>Cancel
  </v-btn>
</template>
<script>
export default {
  name: "AllocationCancelBtn",
  data() {
    return {};
  },
};
</script>